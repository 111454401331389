import { setDefaultOptions } from 'date-fns'
import { enUS, ja } from 'date-fns/locale'
import Gleap from 'gleap'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation, SSRConfig } from 'next-i18next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Inter as InterFont, Manrope as HeadingFont } from 'next/font/google'
import { useCallback, useEffect, useState } from 'react'
import { Provider } from 'urql'
import GoogleTracking from '~/components/GoogleTracking'
import IdleTimerSettingWrapper from '~/components/IdleTimerSetting'
import NoSupportMobileDetectView from '~/components/Layout/NoSupportMobileDetectView'
import PermissionSettingWrapper from '~/components/PermissionSetting'
import configuration from '~/configuration'
import { IUserInformation, Maybe } from '~/core/@types/global'
import { SESSION_VERSION } from '~/core/constants/cookies'
import { useGraphQLRequest } from '~/core/hooks/use-graphQL'
import { Toast as ToastWrapper } from '~/core/ui/Toast'
import { destroySessionCookiesClient } from '~/core/utilities/catch-api-error'
import withClientAuthenticationMiddleware from '~/lib/next/with-client-authentication-middleware'
import withClientSidePagePropsUser from '~/lib/next/with-page-props-user'
import useBoundStore from '~/lib/store'
import '../src/styles/globals.css'
import { SpeedInsights } from '@vercel/speed-insights/react'
import { AGENCY_TENANT, GLEAP_EVENTS } from '~/core/constants/enum'

const AppRouteLoadingIndicator = dynamic(
  () => import('~/core/ui/AppRouteLoadingIndicator'),
  {
    ssr: false
  }
)

const AppRouteLoadingBlockApp = dynamic(
  () => import('~/core/ui/AppRouteLoadingBlockApp'),
  {
    ssr: false
  }
)

const fontFamilySans = InterFont({
  subsets: ['latin'],
  variable: '--font-family-sans',
  fallback: ['system-ui', 'Helvetica Neue', 'Helvetica', 'Arial'],
  preload: true,
  weight: ['300', '400', '500', '600', '700', '800']
})

const fontFamilyHeading = HeadingFont({
  subsets: ['latin'],
  variable: '--font-family-heading',
  fallback: ['--font-family-sans'],
  preload: true,
  weight: ['400', '500']
})

interface DefaultPageProps extends SSRConfig {
  session?: Maybe<Session>
  user?: Maybe<IUserInformation>
}

function App(
  props: AppProps<DefaultPageProps> & { pageProps: DefaultPageProps }
) {
  const { Component, router } = props
  const pageProps = props.pageProps as DefaultPageProps
  const setUser = useBoundStore((state) => state.setUser)
  const configToast = useBoundStore((state) => state.configToast)
  const setToastClose = useBoundStore((state) => state.setToastClose)
  const clearToast = useBoundStore((state) => state.clearToast)
  const showLockApp = useBoundStore((state) => state.showLockApp)
  const titleLockApp = useBoundStore((state) => state.titleLockApp)
  const client = useGraphQLRequest()
  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    const planName = pageProps?.user?.currentTenant?.tenantPlan?.name

    if (
      configuration.key.gleapApiKey &&
      Gleap &&
      Gleap.getInstance().initialized === false &&
      pageProps?.user?.id &&
      pageProps?.user?.currentTenant?.id &&
      planName &&
      router.asPath.search('/careers/') === -1
    ) {
      Gleap.initialize(configuration.key.gleapApiKey)
      Gleap.identify(pageProps.user.id, {
        name: pageProps.user.fullName,
        email: pageProps.user.email,
        // @ts-ignore
        companyId: pageProps.user.currentTenant?.id,
        // @ts-ignore
        // value: pageProps.user.currentTenant.companyKind,
        value: pageProps?.user?.ownTenant
          ? `${pageProps.user.currentTenant.companyKind}-owner`
          : `${pageProps.user.currentTenant.companyKind}`,
        // @ts-ignore
        companyName: pageProps.user.currentTenant?.name,
        plan: planName,
        customData: {
          companyId: pageProps.user.currentTenant.id,
          companyName: pageProps.user.currentTenant?.name
        }
      })
    }
  }, [pageProps?.user])

  const updateCurrentUser = useCallback(() => {
    if (pageProps.user) {
      setUser(pageProps.user)
      if (router.asPath.search('/careers/') === -1) {
        if (pageProps.user.language === 'ja') {
          setDefaultOptions({ locale: ja })
        } else {
          setDefaultOptions({ locale: enUS })
        }
      } else {
        setDefaultOptions({
          // @ts-ignore
          locale: router?.state?.locale === 'ja' ? ja : enUS
        })
      }
    }
  }, [setUser, pageProps?.user])

  useEffect(updateCurrentUser, [updateCurrentUser])

  useEffect(() => {
    if (
      Gleap.getInstance().initialized === true &&
      pageProps?.user &&
      firstLoad
    ) {
      setFirstLoad(false)
      if (pageProps?.user?.currentTenant?.interacted) {
        Gleap.trackEvent(GLEAP_EVENTS.firstJobCreated)
        Gleap.trackEvent(GLEAP_EVENTS.firstCandidateAdded)
        Gleap.trackEvent(GLEAP_EVENTS.firstMemberInvited)
        if (pageProps?.user?.currentTenant?.companyKind !== AGENCY_TENANT) {
          Gleap.trackEvent(GLEAP_EVENTS.firstLocationCreated)
          Gleap.trackEvent(GLEAP_EVENTS.firstDepartmentCreated)
        }
      }
    }
  }, [Gleap, pageProps?.user])

  // Function to clear complete cache data

  const clearCacheData = () => {
    if (window) {
      const version = SESSION_VERSION

      var storageVersion = localStorage.getItem('version')
      if (!storageVersion || storageVersion !== version) {
        sessionStorage.clear()
        localStorage.clear()
        destroySessionCookiesClient()

        setTimeout(() => {
          window.location.reload()
          localStorage.setItem('version', version)
        }, 250)
      }
    }
  }

  useEffect(() => {
    clearCacheData()
  }, [])

  return (
    <Provider value={client}>
      <SessionProvider session={pageProps.session}>
        {router.asPath.search('/careers/') ? <GoogleTracking /> : null}
        <AppRouteLoadingIndicator />
        <FontFamily />

        <PermissionSettingWrapper>
          <NoSupportMobileDetectView>
            <Component {...pageProps} />
          </NoSupportMobileDetectView>
        </PermissionSettingWrapper>
        <IdleTimerSettingWrapper />

        <ToastWrapper
          {...configToast}
          setOpen={() => {
            setToastClose()
            setTimeout(() => {
              clearToast()
            }, 100)
          }}
        />
        {showLockApp ? <AppRouteLoadingBlockApp title={titleLockApp} /> : null}

        {/* Vercel tracking perf */}
        {configuration.production === true ? (
          <SpeedInsights route={router.pathname} />
        ) : null}
      </SessionProvider>
    </Provider>
  )
}
export default appWithTranslation(
  withClientAuthenticationMiddleware(withClientSidePagePropsUser(App), [
    '^/((?!login|register|careers|verify-email|auth|400|404|500|access-denied).)*$'
    // '/((?!api|_next/static|_next/image|favicon.ico|login|register|careers|verify-email|auth|400|500|access-denied).*)'
  ])
)

function FontFamily() {
  return (
    <style jsx global>
      {`
        html {
          --font-family-sans: '-apple-system', 'BlinkMacSystemFont',
            ${fontFamilySans.style.fontFamily}, 'Segoe UI', 'Roboto', 'Ubuntu',
            'sans-serif';

          --font-family-heading: ${fontFamilyHeading.style.fontFamily};
        }
      `}
    </style>
  )
}
