/**
 * @name Enum
 */

export enum HttpStatusCode {
  Ok = 200,
  MovedPermanently = 301,
  MovedTemporarily = 302,
  SeeOther = 303,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  InternalServerError = 500
}

/**
 * @name String Enum
 */

export const LIST_KEY_ERROR_FROM_BACKEND = {
  REGISTER: 'REGISTER'
}

export const DEFAULT_PAGE_SIZE = 25
export const DEFAULT_MAX_PAGE_SIZE = 2500
export const DEFAULT_MOUNT_PAGE_SIZE = 10
export const DEFAULT_LOADING_PAGE_SIZE = 5
export const DEFAULT_ASYNC_LOADING_OPTIONS = 15
export const MAX_CHARACTERS_INTRODUCTION = 10000

export const REMOTE_STATUS = {
  NO_REMOTE: 'no_remote',
  HYDRID_REMOTE: 'hybrid_remote',
  FULLY_REMOTE: 'fully_remote'
}

export const REFERRAL = 'referral'
export const REQUISITION = 'requisition'
export const IMPORT_JOB = 'import_job'
export const SSO = 'sso'

export const DIRECT_EMPLOYER_TENANT = 'employer'
export const AGENCY_TENANT = 'agency'
export const PRIVATE = 'private'
export const PUBLIC = 'public'
export const LOCAL = 'local'
export const CLOUD = 'cloud'
export const RESUME = 'resume'
export const JOB = 'job'
export const PROFILE = 'profile'
export const EMAIL = 'email'
export const PHONE = 'phone'
export const LOCATION = 'location'
export const LINKS = 'links'
export const CONTACT_DETAILS = [JOB, EMAIL, PHONE, LOCATION, LINKS]

export const GLEAP_EVENTS = {
  firstLocationCreated: 'firstLocationCreated',
  firstDepartmentCreated: 'firstDepartmentCreated',
  firstJobCreated: 'firstJobCreated',
  firstCandidateAdded: 'firstCandidateAdded',
  firstMemberInvited: 'firstMemberInvited'
}

export const PROFILE_TABS = {
  INFORMATION: 'information',
  RESUME: 'resume',
  FILES: 'files',
  IN_ACTIVE: 'item-in-active'
}
